import React, { useState, useEffect } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { InlineIcon } from '@iconify/react';
import arrowRight from '@iconify/icons-cil/arrow-right';

import NavBar from '../components/NavBar';
import Head from '../components/Head';

import '../scss/contact.scss';

function Contact ({}) {
    const [navOpen, setNavOpen] = useState(false);
    const navCallback = (state) => {
        setNavOpen(state);
    }

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        FNAME: undefined,
        LNAME: undefined,
        COMPANY: undefined,
        EMAIL: undefined,
        MESSAGE: undefined
    });

   const handleSubmit = async (e) => {
        let incomplete;
        e.preventDefault();
        setFormSubmitted(true);

        Object.keys(formData).map((key, i) => {
            if (!formData[key]) return incomplete = true;
            else return;
        })

        if (!incomplete) {
            const result = await addToMailchimp(formData)
        }
    }

    const Input = ({label, placeholder, name, textarea}) => {

        const handleChange = (e) => {
            let updated = formData;
            updated[name] = e.target.value;
            setFormData(updated);
        }

        return (
            <div className='mc-input'>
                <label className='paragraph'>{label}</label>
                { !formData[name] && formSubmitted &&
                    <span>*Required</span>
                }
                { textarea ? (
                    <textarea
                        placeholder={placeholder}
                        name={name}
                        onChange={handleChange}
                    />
                ) : (
                    <input
                        placeholder={placeholder}
                        name={name}
                        onChange={handleChange}
                    />
                )
                }
            </div>
        )
    }

    return (
        <div className='contact'>
            <Head url="https://northboundfilms.com/contact"></Head>
            <div className='nav-container'>
                <NavBar secondary={true} parentCallback={navCallback}/>
                <div className={(navOpen ? 'disabled-content' : null)} />

                <div className={`mobile-margin-top centered transition2 ${(navOpen ? 'shrink' : null)}`}>
                    <div className='content'>
                        <h1 className='header'>Lets Collaborate</h1>
                        <h2 className='subheader'>Drop us a line</h2>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Input label="First Name" placeholder="John Doe" name="FNAME"/>
                            <Input label="Last Name" placeholder="John Doe" name="LNAME"/>
                            <Input label="Company" placeholder="Northbound Films" name="COMPANY"/>
                            <Input label="Email Address" placeholder="you@company.com" name="EMAIL"/>
                            <Input label="Comments" placeholder="Give us a brief summary... " name="MESSAGE" textarea={true}/>
                            <button type="submit" className='button1'>
                                <h2>Submit</h2>
                                <InlineIcon icon={arrowRight} className='arrow-right'/>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;